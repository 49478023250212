import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface CountryState {
  country: string;
  preferredCountry: string;
  selectedCountries: Set<string>;
  assignCountry: (country: string) => void;
  selectPreferredCountry: (newCountry: string) => void;
  addCountry: (country: string) => void;
  removeCountry: (country: string) => void;
}

const del = (set: Set<string>, item: string) => {
  const newSet = new Set(set);
  newSet.delete(item);
  return newSet;
};

const useStore = create<CountryState>()(
  devtools((set) => ({
    country: '',
    preferredCountry: '',
    selectedCountries: new Set<string>(),
    assignCountry: (country) => set(() => ({ country })),
    selectPreferredCountry: (newCountry) => set(() => ({ preferredCountry: newCountry })),
    addCountry: (country) =>
      set((state) => ({ selectedCountries: new Set(state.selectedCountries).add(country) })),
    removeCountry: (country) => {
      set((state) => ({ selectedCountries: del(state.selectedCountries, country) }));
    },
  })),
);

export default useStore;
