import Link from '@/modules/I18n/components/Link';
import { useRouter } from 'next/router';

export default function NavLink({ href, text }: { href: string; text: string }) {
  const router = useRouter();
  const firstSubpath = `/${href.split('/')[1]}`;
  return (
    <div className="flex w-full items-stretch justify-center">
      <Link
        href={href}
        className={`mx-auto flex w-full items-center justify-center border-transparent py-4 transition-colors hover:border-blue md:border-t-4 ${
          router.route.startsWith(firstSubpath) ? '!border-blue' : ''
        }`}
      >
        {text}
      </Link>
    </div>
  );
}
