import { type StaticImageData } from 'next/image';

interface Options {
  /**
   * Original file name.
   *
   * `{filename}-600w.avif`, `{filename}-400w.avif`, `{filename}-200w.avif`,
   * `{filename}-600w.webp`, `{filename}-400w.webp`, `{filename}-200w.webp`
   * will be used as preferred formats and they have to be present in the
   * same directory.
   */
  src: StaticImageData;
  alt: string;
  /**
   * Media condition(s), separated by commas, followed by amount of horizontal space the
   * image will take after each condition and an optional fallback.
   *
   * Documentation {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/sizes}
   *
   * @example Take up 185px on screens above 768px (desktop), take up 30% else (below 768px).
   * ```css
   * (min-width: 768px) 185px, 30vw
   * ```
   */
  sizes: string;
  loading?: 'lazy' | 'eager';
}

export default function CustomImage(props: Options) {
  const imageName = props.src.src.split('/').pop()?.split('.').slice(0, -2).join('.');
  const extension = props.src.src.split('.').pop();
  return (
    <picture>
      <source
        srcSet={`/images/${imageName}-600w.avif 600w, /images/${imageName}-400w.avif 400w, /images/${imageName}-200w.avif 200w`}
        sizes={props.sizes}
        type="image/avif"
      />
      <source
        srcSet={`/images/${imageName}-600w.webp 600w, /images/${imageName}-400w.webp 400w, /images/${imageName}-200w.webp 200w`}
        sizes={props.sizes}
        type="image/webp"
      />
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={`/images/${imageName}.${extension}`}
        alt={props.alt}
        loading={props.loading || 'lazy'}
        decoding="async"
        width={props.src.width}
        height={props.src.height}
      />
    </picture>
  );
}
