import React from 'react';
import { default as NextLink } from 'next/link';
import { useRouter } from 'next/router';

import getConfig from 'next/config';
import { findMatchingHref } from '../utils';

const { publicRuntimeConfig } = getConfig();
const permalinks: { [key: string]: { [key: string]: string } } =
  publicRuntimeConfig.permalinks || {};

export const i18nFallbackUrls: { [key: string]: string } = Object.entries(permalinks).reduce(
  (acc, [originalSlug, permalinks]) => ({
    ...acc,
    ...Object.entries(permalinks || {}).reduce(
      (acc2, [locale, permalink]) => ({
        ...acc2,
        [`/${locale}${originalSlug}`]: `/${locale}${permalink}`,
        [`/en${permalink}`]: originalSlug,
      }),
      {},
    ),
  }),
  {},
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Link = ({ href, locale, ...props }: any) => {
  const router = useRouter();
  const wantedLocale = locale || router.locale;
  let i18nProps = {
    href,
    locale,
  };

  if (i18nFallbackUrls[`/${wantedLocale}${href}`]) {
    i18nProps = {
      href: i18nFallbackUrls[`/${wantedLocale}${href}`] as string,
      locale: false,
    };
  } else {
    // Eg: fullUrl is /nl/developer/parameters/autocomplete/nl
    const fullUrl = `/${wantedLocale}${href}`;
    const translatedUrl = findMatchingHref(i18nFallbackUrls, fullUrl);
    if (translatedUrl) {
      i18nProps = {
        href: translatedUrl as string,
        locale: false,
      };
    }
  }
  return <NextLink {...i18nProps} {...props} />;
};

export default Link;
