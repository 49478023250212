import getConfig from 'next/config';
import { type NextRouter } from 'next/router';

const removeSlugFromUrl = (href: string) => {
  return href.replace(`/:slug*`, '');
};

const concatenateSlugToUrl = (href: string, slug: string) => {
  return `${href}${slug}`;
};

const getCallCountryField = (href: string) => {
  if (typeof href === 'string') {
    const urlParts = href.split('/');
    if (urlParts.length === 7) {
      // Contains /:field (suggest call)
      return { call: urlParts[3], country: urlParts[4], field: urlParts[5] };
    } else if (urlParts.length === 6) {
      return { call: urlParts[3], country: urlParts[4] };
    }
  }
  return { call: null, country: null, field: null };
};

/**
 * From all i18nFallbackUrls check which ones contain the `:slug*`
 * From all of those check if it matches fullUr
 * /nl/developer/autocomplete/nl/parameters should match
 * /nl/developer/:call/:country/parameters
 * @param i18nFallbacks
 * @param href
 * @returns
 */
export const findMatchingHref = (i18nFallbacks: { [key: string]: string }, href: string) => {
  let hrefSubstring: string | null = null;
  let translatedHrefWithSlug: string | null = null;
  let slugHref: string | null = null;

  for (const key in i18nFallbacks) {
    if (key.endsWith('/:slug*')) {
      // Case where the url from permalinks.json ends with /:slug*
      const keyWithoutSlug = removeSlugFromUrl(key);
      if (href.includes(keyWithoutSlug)) {
        if (hrefSubstring === null || keyWithoutSlug.length > hrefSubstring.length) {
          hrefSubstring = keyWithoutSlug;
          if (i18nFallbacks[key] !== undefined) {
            translatedHrefWithSlug = i18nFallbacks[key]!;
            // Remove the similar part from both urls and the remaining is the implicit :slug*
            slugHref = href.replace(hrefSubstring, ''); // Contains opening `/`
          }
        }
      }
    } else if (key.includes('/:call') && key.includes('/:country') && key.includes('/:field')) {
      const fixedKeys = key.split('/:call/:country/:field'); // eg: ["/developer", "/parameters"]
      if (
        fixedKeys.length === 2 &&
        href.includes(fixedKeys[0] as string) &&
        href.includes(fixedKeys[1] as string)
      ) {
        // /suggest urls contain also /:field
        const { call, country, field } = getCallCountryField(href);
        if (!call && !country && !field) return;
        if (i18nFallbacks[key] !== undefined) {
          const translatedUrlWithPlaceholders = i18nFallbacks[key];
          if (call && country && field) {
            let translatedUrl = translatedUrlWithPlaceholders?.replace(':call', call);
            translatedUrl = translatedUrl?.replace(':country', country as string);
            translatedUrl = translatedUrl?.replace(':field', field);
            return translatedUrl;
          } else if (call && country && !field) {
            let translatedUrl = translatedUrlWithPlaceholders?.replace(':call', call);
            translatedUrl = translatedUrl?.replace(':country', country as string);
            translatedUrl = translatedUrl?.replace('/:field', '');
            return translatedUrl;
          }
        }
      }
    }
  }
  if (hrefSubstring !== null && translatedHrefWithSlug !== null && slugHref !== null) {
    // Translated href looks like: /ontwikkelaar/parameters/:slug*
    // Remove slug and concatenate the difference between urls
    const translatedHrefWithoutSlug = removeSlugFromUrl(translatedHrefWithSlug);
    const translatedUrl = concatenateSlugToUrl(translatedHrefWithoutSlug, slugHref);
    return translatedUrl;
  }
  return null;
};

const { publicRuntimeConfig } = getConfig();
const permalinks: { [key: string]: { [key: string]: string } } = publicRuntimeConfig.permalinks;

export const verifySelectedMenuByRoute = (mainHref: string, router: NextRouter) => {
  let hrefSubstring: string | null = null;
  let routeWithoutSlug: string | null = null;
  if (router.locale === 'en') {
    if (router.asPath.startsWith(mainHref)) return true;
    else {
      const fixedKeys = mainHref.split('/');
      if (
        router.asPath.includes(fixedKeys[1] as string) &&
        router.asPath.includes(fixedKeys[2] as string)
      )
        return true;
    }
  }
  if (router.locale === 'en' && router.asPath.startsWith(mainHref)) return true;
  Object.keys(permalinks).forEach((route: string) => {
    if (route.endsWith('/:slug*')) {
      routeWithoutSlug = removeSlugFromUrl(route);
    } else if (route.includes('/:call/:country/:field')) {
      routeWithoutSlug = route.replace('/:call/:country/:field', '');
    } else if (route.includes('/:call/:country')) {
      routeWithoutSlug = route.replace('/:call/:country', '');
    } else {
      routeWithoutSlug = route;
    }
    if (mainHref === routeWithoutSlug) {
      const hrefValues = permalinks[route];
      // Found the translations
      if (hrefValues) {
        hrefSubstring = hrefValues[router.locale as string] as string;
        if (hrefSubstring) {
          if (hrefSubstring.endsWith(`/:slug*`)) {
            hrefSubstring = hrefSubstring.replace(`/:slug*`, '');
          } else if (hrefSubstring.includes('/:call/:country/:field')) {
            hrefSubstring = hrefSubstring.replace('/:call/:country/:field', '');
          } else if (hrefSubstring.includes('/:call/:country')) {
            hrefSubstring = hrefSubstring.replace('/:call/:country', '');
          }
        }
      }
    }
  });
  if (!hrefSubstring) return false;
  if (router.asPath.startsWith(hrefSubstring)) return true;
  else {
    // hrefSubstring eg value: /developpeur/parametres
    const fixedKeys = hrefSubstring.split('/');
    if (router.asPath.includes(fixedKeys[1]) && router.asPath.includes(fixedKeys[2])) return true;
  }
  return false;
};

// Ensure that for each locale-specific URL,
// it includes mappings from all other locales' permalinks to the corresponding locale's permalink
const switchLanguageI18nFallbackUrls: { [key: string]: string } = Object.entries(permalinks).reduce(
  (acc, [originalSlug, localeMap]) => {
    // Iterate through each locale and its corresponding permalink
    Object.entries(localeMap).forEach(([locale, permalink]) => {
      // Add the mapping for the locale
      acc[`/${locale}${originalSlug}`] = `/${locale}${permalink}`;

      // Add mappings for other locales' permalinks to this locale's permalink
      Object.entries(localeMap).forEach(([otherLocale, otherPermalink]) => {
        if (locale !== otherLocale) {
          acc[`/${locale}${otherPermalink}`] = `/${locale}${permalink}`;
        }
      });
    });

    // Add the English fallback mapping
    Object.values(localeMap).forEach((permalink) => {
      acc[`/en${permalink}`] = originalSlug;
    });

    return acc;
  },
  {},
);

/**
 * Used for the LanguageSwitcher to translate current url to the desired locale.
 * @param locale
 * @param router
 * @returns
 */
export const getTranslatedUrl = (locale: string, router: NextRouter) => {
  let updatedUrl: string | null = null;
  if (switchLanguageI18nFallbackUrls[`/${locale}${router.asPath}`]) {
    updatedUrl = switchLanguageI18nFallbackUrls[`/${locale}${router.asPath}`] as string;
  } else {
    const fullUrl = `/${locale}${router.asPath}`;
    const translatedUrl = findMatchingHref(switchLanguageI18nFallbackUrls, fullUrl);
    if (translatedUrl) updatedUrl = translatedUrl;
  }
  return updatedUrl;
};
