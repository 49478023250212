import { getCookie, getCookies } from 'cookies-next';
import fetcher from './fetcher';

function authorizedFetcher<T>(url: string, init: RequestInit | undefined = undefined) {
  const requestConfig: RequestInit = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': `${getCookie('csrf_access_token')}`,
      Cookie: `${getCookies()}`,
    },
  };

  return fetcher<T>(url, {
    ...requestConfig,
    ...init,
  }).catch((error) => {
    // Rethrow error to the next catch block
    throw error;
  });
}

export default authorizedFetcher;
