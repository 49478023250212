import React from 'react';
import { useRouter } from 'next/router';
import { setCookie } from 'cookies-next';

import { useTranslation } from 'next-i18next';
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter';
import { getTranslatedUrl } from '@/modules/I18n/utils';

export default function LanguageSwitcher() {
  const router = useRouter();

  const { t, i18n } = useTranslation(['common']);

  function setLocale(locale: string) {
    setCookie('NEXT_LOCALE', locale, {
      maxAge: 12 * 30 * 24 * 60 * 60, // 12 months
      path: '/',
    });
  }

  function localizedLanguage(locale: string) {
    const displayNames = new Intl.DisplayNames(locale, { type: 'language' });
    return displayNames.of(locale);
  }

  const changeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const locale = e.currentTarget.value;
    // Language is switched
    if (locale !== router.locale) {
      setLocale(locale);
      i18n.changeLanguage(locale);

      // Update URL based on the permalinks
      // If previously was NL then router.asPath = "/prijzen"
      // Switched to english then it should be "/pricing"
      let currentUrl = router.asPath;
      const translatedUrl = getTranslatedUrl(locale, router);
      if (translatedUrl) currentUrl = translatedUrl;
      router.replace({ pathname: router.asPath, query: router.query }, currentUrl, { locale });
    }
  };
  return (
    <div className="relative mx-auto inline-block w-1/4 text-center md:w-auto" translate="no">
      <label htmlFor="locale" className="sr-only">
        {t('choose_language_label')}
      </label>
      <select
        name="locale"
        id="locale"
        data-test-id="language-switcher"
        value={router.locale}
        className="bg-white"
        onChange={(e) => changeLanguage(e)}
      >
        {router.locales?.map((locale) => {
          return (
            <option key={locale} value={locale}>
              {/* English name */}
              {capitalizeFirstLetter(t(locale, { lng: 'en' }))}
              {/* Localized name if not English */}
              {locale !== 'en' ? ` (${localizedLanguage(locale)})` : null}
            </option>
          );
        })}
      </select>
    </div>
  );
}
