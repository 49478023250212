import { useTranslation } from 'next-i18next';
import { deleteCookie, getCookie } from 'cookies-next';
import Link from '@/modules/I18n/components/Link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import authorizedFetcher from '@/utils/requests/authorizedFetcher';
import { useClickAway } from 'react-use';
import Person from '@/components/SVG/Person';
import type { GetMeResponse } from '@/utils/requests/CMS/SWR/getMe';

const LoginButton = () => {
  const router = useRouter();
  const { t } = useTranslation(['common', 'cms']);
  const [dropdownShown, setDropdownShown] = useState(false);

  // Only csrf token is readable since it is not httpOnly. If changed to httpOnly, this will not work.
  // You will need to manually set a cookie in the middleware response.
  const loggedIn: boolean = getCookie('csrf_access_token') !== undefined;

  const { data, error } = useSWR<GetMeResponse>(
    loggedIn ? `${process.env.NEXT_PUBLIC_INTERNAL_API}/v2/accounts/me` : null,
    authorizedFetcher,
    {
      onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
        // Never retry on 404.
        if (error.status === 404) return;

        // Only retry up to 5 times. Then Logout.
        if (retryCount >= 5) handleLogout();

        // Retry after 500 ms.
        setTimeout(() => revalidate({ retryCount }), 500);
      },
    },
  );

  const ref = useRef(null);
  useClickAway(ref, () => {
    setDropdownShown(false);
  });

  useEffect(() => {
    if (loggedIn) return;
    if (router.pathname.startsWith('/cms')) {
      router.replace(`/login`);
    }
  }, [loggedIn, router]);

  useEffect(() => {
    setDropdownShown(false);
  }, [router]);
  if (error) return <div>Failed to load</div>;

  async function handleLogout() {
    await LogoutRequest()
      .then((r) => {
        if (!r.ok) throw new Error(r.statusText);
      })
      .then(() => {
        // Fixes a bug where the cookie is not deleted on logout.
        deleteCookie('csrf_access_token');
        router.replace(`/login`);
      });
  }

  return (
    <>
      {data?.account ? (
        <div className="relative">
          <button
            className="rounded border-2 border-blue p-2 text-blue"
            type="button"
            onClick={() => setDropdownShown(!dropdownShown)}
            aria-label="Menu"
            data-test-id="user-name-nav"
          >
            <Person width={20} height={20} className="inline" />
            {data?.account.name}
          </button>
          <div
            className={`absolute min-w-full flex-col bg-white text-center normal-case shadow-md ${
              dropdownShown ? 'flex' : 'hidden'
            }`}
            ref={ref}
          >
            <Link
              href={`${process.env.NEXT_PUBLIC_CMS_BASE_PATH}`}
              scroll={false}
              className="border border-b-0 border-gray-darker p-1"
              data-test-id="subscriptions-button"
            >
              {t('subscriptions')}
            </Link>
            <Link
              href={`${process.env.NEXT_PUBLIC_CMS_BASE_PATH}/account-details`}
              scroll={false}
              className="border border-b-0 border-gray-darker p-1"
              data-test-id="account-button"
            >
              {t('account_details')}
            </Link>
            <Link
              href=""
              scroll={false}
              className="border border-gray-darker p-1"
              onClick={handleLogout}
              data-test-id="logout-button"
            >
              {t('logout')}
            </Link>
          </div>
        </div>
      ) : (
        <div className="relative">
          <Link
            href="/login"
            data-test-id="login-button"
            className="inline-block rounded border-gray bg-blue px-12 py-2 text-center text-white"
          >
            {t('nav_login')}
          </Link>
        </div>
      )}
    </>
  );
};

function LogoutRequest() {
  return fetch(`${process.env.NEXT_PUBLIC_INTERNAL_API}/v2/auth/logout`, {
    method: 'POST',
    credentials: 'include',
  });
}

export default LoginButton;
