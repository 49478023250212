function fetcher<T>(url: string, init: RequestInit | undefined = undefined): Promise<T> {
  return fetch(url, init)
    .then(async (r) => {
      // https://github.com/vercel/swr/discussions/634#discussioncomment-61164
      if (!r.ok) throw new Error(await r.text());

      if (r.status === 204) return '';

      return r.json();
    })
    .then((data) => data as T)
    .catch((error) => {
      // Throw error to the next catch block, so we can handle it there.
      throw error;
    });
}

export default fetcher;
