import { useTranslation } from 'next-i18next';
import Link from '@/modules/I18n/components/Link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Logo from '@/images/logo.png';

import LanguageSwitcher from '@/components/LanguageSwitcher';
import NavLink from '@/components/NavLink';
import LoginButton from './CMS/LoginButton';
import CustomImage from './CustomImage';
import useSWR from 'swr';
import useStore from '@/store/user';
import fetcher from '@/utils/requests/fetcher';

export default function Header() {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [shown, setShown] = useState(false);

  const updateUserCountry = useStore((state) => state.assignCountry);
  const { data } = useSWR(`/api/origin`, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (data?.country) {
      updateUserCountry(data.country);
    }
  }, [data, updateUserCountry]);

  return (
    <>
      <header className="sticky top-0 z-10 bg-white shadow-md">
        <section className="hidden w-full bg-gray-lighter p-2 text-center text-sm md:block">
          {t('header_support1')}&nbsp;
          <Link href="/support" className="font-bold underline">
            {t('nav_support')}
          </Link>
          &nbsp;
          {t('header_support2')}&nbsp;
          <Link href="mailto:tech@pro6pp.nl" className="font-bold">
            tech@pro6pp.nl
          </Link>
          &nbsp;
          {t('header_support3')}&nbsp;
          <Link href="mailto:support@pro6pp.nl" className="font-bold">
            support@pro6pp.nl
          </Link>
          &nbsp;
          {t('header_support4')}
        </section>
        <nav className="mx-auto max-w-7xl flex-col items-stretch justify-between text-lg font-bold capitalize md:flex md:flex-row">
          <div className="flex flex-shrink-0 justify-between px-3 py-2">
            <Link href="/" locale={router.locale} className="flex items-center">
              <CustomImage
                src={Logo}
                alt={t('logo', { company: 'Pro6PP' })}
                sizes="50vw"
                loading="eager"
              />
            </Link>
            <button
              aria-label="Toggle the menu"
              className="md:hidden"
              type="button"
              onClick={() => setShown(!shown)}
            >
              <svg
                className="h-8 w-8"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  fillRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div
            className={`flex flex-shrink-0 scale-0 flex-col items-center px-3 transition-all md:flex md:max-h-full md:scale-100 md:flex-row md:items-stretch md:gap-[1.5vw] ${
              shown ? 'max-h-screen scale-100' : 'max-h-0 overflow-hidden'
            }`}
          >
            <NavLink href="/demo/address-validation/nl" text={t('nav_demos')} />
            <NavLink href="/webshops" text={t('nav_webshops')} />
            <NavLink href="/pricing" text={t('nav_pricing')} />
            <NavLink href={`/downloads/nl`} text={t('nav_downloads')} />
            <NavLink href="/support" text={t('nav_support')} />
            <NavLink href="/developer" text="API" />
            <NavLink href="/news" text={t('news')} />
          </div>
          <div
            className={`flex scale-0 flex-col items-center justify-items-end gap-4 px-3 transition-all md:my-0 md:flex md:max-h-full md:scale-100 md:flex-row md:py-2 ${
              shown
                ? 'max-h-screen scale-100 py-2'
                : 'max-h-0 overflow-hidden py-0 md:overflow-visible'
            }`}
          >
            <LanguageSwitcher />
            <LoginButton />
          </div>
        </nav>
      </header>
    </>
  );
}
